@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.primary.MuiButton-root:hover {
  text-decoration: none !important;
  background-color: rgba(38, 132, 255, 0.6) !important;
}

.pincodeNumber input::-webkit-outer-spin-button,
.pincodeNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pincodeNumber input[type="number"] {
  -moz-appearance: textfield;
}

.settings-header {
  padding: 50px 0 10px 0;
  background: #fffae6;
}
.ant-segmented{
  height: fit-content;
}
.ant-segmented-item-selected {
  background-color: #1C75BC;
  color: white;
}



/* Navbar 🟢*/
.nav .ant-radio-button-wrapper {
  position: relative;
  display: unset;
  height: auto;
  margin: 0;
  padding: 0.35rem 1rem;
  color: black;
  font-size: 14px;
  line-height: 30px;
  background: #e8e8f0;
  border: unset;
  border-top-width: unset;
  border-left-width: unset;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.nav .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  @apply bg-primary hover:bg-primary text-white hover:text-white  border-0;
  border-radius: 9px;
}
.nav label.ant-radio-button-wrapper.radioCls {
  @apply hover:bg-white hover:text-primary hover:shadow-sm;
  border-radius: 9px;
}

.nav .ant-radio-button-wrapper:last-child::after {
  display: none;
}

.nav .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.nav .ant-picker:hover,
.ant-picker-focused {
  border: 1px solid transparent;
}

.nav .ant-picker {
  box-sizing: border-box;
  margin: 0;
  /* padding: 0; */
  color: black !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: unset;
  list-style: none;
  /* padding: 0px 0px 0px 5px; */
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}

.nav .ant-picker-input.ant-picker-input {
  width: 106px;
  /* width: 34px; */
}

.nav .ant-picker-input > input::placeholder {
  @apply text-black;
}

.nav .ant-picker-suffix {
  @apply text-black;
  padding-right: 5px;
  margin-left: 0%;
}

.nav span.ant-picker-clear {
  font-size: 25px;
}

.nav .ant-picker-range .ant-picker-clear {
  right: 13px;
}

/* Navbar 🔴*/

/* Table 🟢*/
.ant-table-header.ant-table-sticky-holder {
  top: 40px;
}
/* Navbar 🔴*/

.btn_primary {
  @apply bg-primary duration-500 text-white hover:text-primary rounded-full;
  padding: 10px 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.btn_primary :hover {
  background-color: #fffbfe;
}

.htl {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
}

.vl {
  left: 50%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
}

.hl {
  top: 50%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 10px;
}

.vl-last {
  left: 50%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 20px;
}

.hl-last {
  top: 98%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 10px;
}

.forgot-password {
  margin-top: 30;
  text-align: right;
}

.table-fixed-header {
  position: fixed;
}

.Input-field {
  width: "100%";
}

.Title {
  top: 40px;
  left: 40px;
  right: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #283891;
}

.Sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 162.02%;
  text-align: center;
  font-feature-settings: "salt" on;
  color: #7a828a;
}

.Input {
  background: #ffffff;
  border: 1px solid rgba(40, 56, 145, 0.05);
  box-sizing: border-box;
  width: 431px;
  margin-top: 9px;
  height: 46px;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  border-radius: 6px;
}

.Input-label {
  display: inline-block;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #7a828a;
}

.Input-hint {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #7a828a;
}

.Forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  margin-top: 30px;
  display: flex;
  display: inline-block;
  width: 80%;
  text-align: right;
  color: #283891;
  text-transform: none;
}

.Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #283891;
  border-radius: 4px;
}

.item {
  display: flex;
  justify-content: center;
}

.item-left {
  display: flex;
  justify-content: left;
}

.item .item-image {
  width: 28px;
  height: 28px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in -out;
}

.item .item-title {
  height: 12px;
  width: 50px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.item-left .item-title {
  height: 12px;
  width: 50px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.buyboxtag {
  background: #fff0b3;
  border: 1px solid #ffe380;
  padding: 2px 6px;
  border-radius: 3px;
}

.drawer-container .ant-drawer-content {
  overflow: initial;
}

.settings-tab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.settings-tab .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.settings-tab .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.settings-tab .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
  border-left: 0;
}

.settings-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.settings-tab .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #fff;
}

.settings-tab .ant-tabs .ant-tabs-tab {
  padding: 12px 20px;
  border-bottom-width: 2px;
  border-bottom-color: transparent;
}

.settings-tab .ant-tabs-nav::before {
  display: none;
}

.settings-tab .ant-tabs-nav-list {
  border-bottom: 1px solid #f0f0f0;
}

.settings-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom-width: 2px;
}

.settings-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.settings-tab .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active,
.settings-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.settings-tab .ant-tabs-tab:hover {
  color: #ff8b00;
  border-bottom-color: #ff8b00;
}

.aliceblue {
  background-color: aliceblue;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 0.25rem !important;
}

.width-30 {
  width: 30%;
}

.width-45 {
  width: 45%;
}

.width-48 {
  width: 48%;
}

.width-49 {
  width: 49.5%;
}

.call-to-action-b-t {
  border-top: 1px solid rgba(223, 225, 230, 0.5);
}

.small-h {
  color: #44444f;
  font-weight: 500;
}
.Border-radius-3 {
  border-radius: 3px;
}

.padding-36-90 {
  padding: 36px 90px;
}

/*Table*/

/* table {
  width: 100%;
  overflow: scroll;
}
table td.mat-cell, table td.mat-footer-cell, table th.mat-header-cell {
  padding-left: 10px;
  touch-action: pan-x !important;
}
table tr:nth-child(odd) td {
  background: #f8fbff;
}
table tr:nth-child(even) td {
  background: #fff;
}
table tr:hover td {
  background: #f3f3f3;
} */

/*Table*/

.font-32 {
  font-size: 32px;
}
.height-fit {
  height: fit-content;
}
.bk-nav {
  background-color: transparent;
  box-shadow: rgb(23 43 77 / 4%) 0px 0px 2px, rgb(23 43 77 / 8%) 0px 3px 2px;
}
.bk-white {
  background-color: transparent;
}
.ant-form-item {
  margin-bottom: 0%;
}

.MuiBox-root-1 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.glass {
  padding: 8px 6px;
  background-color: #f0f3e8;
  border-radius: 16px;
  border: 1px solid #dfe4d6;
}

.new_h2 {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
}

.new_shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ant-table-wrapper {
  /* overflow: auto; */
  border-radius: 10px;
}

.primary_btn {
  color: #3f51b5;
  border: 1px solid currentColor;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.primary_btn:hover {
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
}

.MuiPaper-root.MuiCard-root.MuiPaper-elevation0.MuiPaper-rounded {
  background: transparent;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: unset !important;
}

.new_btn {
  background: #1c75bc;
  border: #1c75bc;
}
.new_btn:hover {
  background: white;
  border: 1px solid #1c75bc;
  color: #1c75bc;
}

.card_hover:hover {
  transform: scaleX(1.05);
}

.ant-image-preview-wrap {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-preview-body {
  position: unset !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-preview-img-wrapper {
  position: unset !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-image-preview-img {
  width: fit-content !important;
  height: fit-content !important;
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.font-gray{
  color: #3d3d3d;
}

.text_fs_dark{
  color: #000E5E !important;
}

.text_fs{
  color: #1C75BC;
}

.text_fs_light{
  color: #27AAE1;
}

.dashboard_card{
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  background-color: #DFE0FF;
  padding: 16px 15px;
  border-radius: 15px;
}

.neuromorphism{
  background: #ecf0f3;
    box-shadow: -3px -3px 7px #ffffff, 3px 3px 5px #ceced1;
}
.dashboard_card_hover:hover {
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.ant-checkbox-inner {
   /* width: 24px; */
  /* height: 24px; */
  border-width: 2px;
  /* border-radius: 5px;  */
  border-color: rgb(4, 31, 81);
}

/* .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #1c75bc;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: gray;
  border-color: gray;
  color: white;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: white;
  border-color: #1c75bc;
  color: #1c75bc;
} */

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.table-wrapper {
  overflow-x: auto;
}

.table-wrapper::-webkit-scrollbar {
  height: 8px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: var(--darkblue);
  border-radius: 40px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 40px;
}

.table-wrapper table {
  margin: 50px 0 20px;
  border-collapse: collapse;
  text-align: center;
}

.table-wrapper table th,
.table-wrapper table td {
  padding: 10px;
  min-width: 75px;
}

.table-wrapper table th {
  color: var(--white);
  background: var(--darkblue);
}

.table-wrapper table tbody tr:nth-of-type(even) > * {
  background: var(--lightblue);
}

.table-wrapper table td:first-child {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 10px;
  text-align: left;
}
.ant-list-item:hover {
  box-shadow: 0 0 4px #eee;
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.des_mak_title{
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
}
.font-es{
  font-size: 11px;
}
.border-custom{
  border: 1px solid #C7D6DD;
}

.card-bg-custom {
  background: #E0E6E9;
  border: 1px solid #C7D6DD;
}